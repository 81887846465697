<template>
   <div id="chart2">
        <apexchart type="line" width="150" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
export default {
   
     name: 'donutchart',
    setup() {
      return {
 series: [{
              name: "Desktops",
              data: [10, 41, 35, 15]
          }],
          chartOptions: {
            chart: {
              widht:'100%',
              height: 50,
              
              type: 'line',
              zoom: {
                enabled: false
              },
               toolbar: {
              show: false
            }
            },
           
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight',
              width: 2,
            },            
            grid: {
              show: false
              /*
              row: {
                colors: ['#FFFFFF', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.0
              },*/
            },
              yaxis:{
              labels:{
                show: false
              }
            },
            xaxis: {
               labels: {
                  show: false
                },    
              categories: ['Jan', 'Feb', 'Mar', 'April'],
            }
          },
          
           
          // end 
      
    }  
    },
}
</script>
<style>
#chart2{
     max-width: 300px;
     margin: 5px 5px 5px 5px;
    
}
</style>